.repositories .description {
    color: #666666;
    padding: 0.5rem 1rem;
    border-right: 1px solid #EEEEEE;
    border-bottom: 1px solid #EEEEEE;
    border-left: 1px solid #EEEEEE;
}

.repositories .language {
    margin-top: 0.5rem;
}
.repositories .item {
    padding: 0.25rem 0.5rem;
    border-radius: 0.25rem;
    border: none;
    font-size: smaller;
    transition-property: all;
    transition-duration: 0.5s;
}
.repositories .html {
    color: #FFFFFF;
    background-color: #ea642d;
}
.repositories .html:hover {
    background-color: #df4e26;
}
.javascript {
    background-color: #eab925;
    color: #FFFFFF;
}
.javascript:hover {
    background-color: #e09e27;
}
.css {
    background-color: #2ea5d6;
    color: #fff;
}
.css:hover {
    background-color: #0c6fb2;
}
.repositories .name {
    background-color: #EEEEEE;
    padding: 0.5rem 1rem;
    display: flex;
    justify-content: space-between;
}
.stat {
    display: flex;
}
.stat span {
    display: flex;
    align-items: center;
}