
html, body, #root {
  height: 100%;
}

#root {
  display: flex;
}

.nav-search-box::placeholder {
  font-style: italic;
}

.nav-search-box:focus {
  box-shadow: none;
  outline: none;
  
}
textarea:focus, 
textarea.form-control:focus, 
input.form-control:focus, 
input[type=text]:focus, 
input[type=password]:focus, 
input[type=email]:focus, 
input[type=number]:focus, 
[type=text].form-control:focus, 
[type=password].form-control:focus, 
[type=email].form-control:focus, 
[type=tel].form-control:focus, 
[contenteditable].form-control:focus {
  box-shadow: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.twitter-color {
  color: rgba(29,161,242,1.00);
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.menu {
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 100;
  height: 100px;
  
}
.menu .nav {
  display: flex;
  height: auto;
}

.nav-link {
  width: 80px;
  height: 80px;
  display: flex !important;
  justify-content: center;
  align-items: center;
}

.sidebar {
  display: none;
  height: 100%;
}
.sidebar .profile-box {
  flex-grow: 1;
  padding: 1rem;
  position: relative;
}

.btn-profile {
  position: absolute;
  right: -0.75rem;
  font-size: 1.5rem;
  top: 50%;
  background-color: white;
  border-radius: 100%;
  padding-bottom: 0.5rem;
  cursor: pointer;
}
.btn-profile:hover {
  transform: rotate(180deg);
  transition-duration: 500ms;
}

.header {
  display: flex;
  border-bottom: 1px solid #e1e1e1;
}

/* width */
::-webkit-scrollbar {
  width: 4px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #eee; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #333; 
  border-radius: 4px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555; 
}

@media screen and (min-width: 768px) {
  .sidebar {
    width: 444px;
    position: fixed;
    display: flex;
    left: 100px;
  }
  .main {
    flex-grow: 1;
    margin-left: 544px;
    display: block;
  }
  .menu {
    height: 100%;
    width: 100px;
  }
  .menu .nav {
    flex-direction: column;
    height: 100%;
  }

  .nav-link {
    width: 100px;
    height: 100px;
  }
}